import React, { Component, createRef } from 'react'
import Slider from 'react-slick'
import Icon from '@/components/utils/Icon'
import rightArrow from '@/icons/ui-right-arrow.svg'
import leftArrow from '@/icons/ui-left-arrow.svg'

const icons = {
	right: rightArrow,
	left: leftArrow
}

const Noop = () => null

//
const Pager = ({ i, onClick }) => (
	<a
		href="#0"
		onClick={onClick}
		className="rounded-full block trans w-10 h-10 bg-teal mr-1 hover:bg-orange pager-item"
	>
		<span className="visuallyhidden">go to slide {i + 1}</span>
	</a>
)

const Button = ({ type, onClick, disabled }) => (
	<button
		type="button"
		className={`ml-1/24 trans ${disabled ? 'opacity-50' : 'hover:text-orange'}`}
		onClick={onClick}
		disabled={disabled}
	>
		<Icon icon={icons[type]} />
		<span className="visuallyhidden">go {type}</span>
	</button>
)

export default class Slide extends Component {
	state = {
		slideIndex: 0
	}

	slider = createRef()

	isPrevDisabled = slideIndex => slideIndex === 0

	isNextDisabled = (slideIndex, count) => {
		const { slidesToShow } = this.props

		return slideIndex === count - slidesToShow
	}

	render() {
		const {
			content,
			slidesToShow,
			render,
			dots = false,
			dotsMd = false,
			wrapperClassName = '',
			btnWrapperClassName = '',
			gutter = '1-5'
		} = this.props

		const { slideIndex } = this.state

		const settings = {
			dots: false,
			infinite: false,
			speed: 250,
			initialSlide: 0,
			slidesToShow,
			swipeToSlide: true,
			touchMove: true,
			slidesToScroll: 1,
			nextArrow: <Noop />,
			prevArrow: <Noop />,
			wrapperClassName,
			dotsClass: 'pt-1 pl-2-5 pr-1 list-reset slick-pager flex items-center',
			customPaging: i => <Pager i={i} />,
			afterChange: slideIndex => this.setState({ slideIndex }),
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						dots: dotsMd
					}
				},

				{
					breakpoint: 760,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						dots
					}
				}
			]
		}

		const showButtons = content.length > slidesToShow

		return (
			<div className={`-ml-${gutter} ${wrapperClassName}`}>
				<Slider {...settings} ref={this.slider}>
					{content.map(item => (
						<div
							key={item.id}
							className={`flex flex-col w-full trans trans-opacity outline-none pl-${gutter}`}
						>
							{render(item)}
						</div>
					))}
				</Slider>
				{showButtons && (
					<div className={`absolute pin-t pin-r ${btnWrapperClassName}`}>
						<div className="pl-0-5 hidden md:block">
							<Button
								onClick={() => this.slider.current.slickNext()}
								type="right"
								disabled={this.isNextDisabled(slideIndex, content.length)}
							/>
							<div className="h-px bg-white-30 w-full my-0-75" />
							<Button
								onClick={() => this.slider.current.slickPrev()}
								type="left"
								disabled={this.isPrevDisabled(slideIndex)}
							/>
						</div>
					</div>
				)}
			</div>
		)
	}
}
